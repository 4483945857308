@import '~antd/dist/antd.css';
@import './_variables.scss';

@import url('https://fonts.googleapis.com/css2?family=Kalam:wght@300&display=swap');

ul {
  padding-inline-start: 5px;
}

#components-layout-demo-fixed-sider .logo {
  height: 32px;
  margin: 16px 0 5px 0;
  font-family: "Kalam";
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.6em;
  color: wheat;
}
.site-layout .site-layout-background {
  background: #fff;
}

pre {
  font-family: "proxima-nova", 'Proxima Nova', sans-serif;
}

.ant-layout {
  background-color: $content-background-color;
}

.page-title {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.full-width {
  width: 100%;
}