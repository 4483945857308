@import url(https://fonts.googleapis.com/css2?family=Kalam:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ul{-webkit-padding-start:5px;padding-inline-start:5px}#components-layout-demo-fixed-sider .logo{height:32px;margin:16px 0 5px 0;font-family:"Kalam";display:flex;justify-content:center;align-items:center;font-size:1.6em;color:wheat}.site-layout .site-layout-background{background:#fff}pre{font-family:"proxima-nova", 'Proxima Nova', sans-serif}.ant-layout{background-color:#e0e0eb}.page-title{display:flex;flex-flow:column;align-items:center}.full-width{width:100%}

.App_Sider__25rUn{overflow:auto;height:100vh;position:fixed;left:0;transition:-webkit-transform .4s;transition:transform .4s;transition:transform .4s, -webkit-transform .4s;z-index:1;width:200px}.App_MenuButton__HHlYh{top:15px;left:15px;position:fixed;z-index:2;display:none}.App_Container__3mTNK{height:100vh;overflow:auto;margin-left:200px;transition:margin .4s}@media (max-width: 600px){.App_Collapsed__2nMn0{-webkit-transform:translateX(-200px);transform:translateX(-200px)}.App_Collapsed__2nMn0+.App_Container__3mTNK{margin-left:0}.App_MenuButton__HHlYh{display:block}}

